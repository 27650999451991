body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  text-align: center;
  margin-top: 50px;
}

.title {
  font-size: 28px;
  margin-bottom: 20px;
}

.input-container {
  margin-bottom: 20px;
}

input {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generate-button {
  margin-left: 10px;
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.qrcode-container {
  margin-top: 20px;
}

.download-container {
  margin-top: 20px;
}

.file-name-input {
  margin-right: 10px;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.download-button {
  padding: 8px 12px;
  font-size: 16px;
  cursor: pointer;
  background-color: #008cba;
  color: #fff;
  border: none;
  border-radius: 4px;
}
